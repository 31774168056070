<template>
  <div>
    <a-modal v-model:visible="show" destroyOnClose :title="isEdit ? '编辑分账组' : '新增分账组'" @ok="onAddGroupFinish"
             @cancel="onAddGroupClose" :maskClosable="false" :keyboard="false">
      <a-form ref="addForm" name="addForm" :model="addFormState">
        <a-row>
          <a-form-item class="ui-form__item" name="cinemaIds" label="所属影院"
                       :rules="[{ required: true, message: '请选择影院' }]">
            <a-select placeholder="请选择" mode="multiple" v-model:value="addFormState.cinemaIds" style="width: 300px;"
                      :disabled="!!isEdit">
              <a-select-option v-for="item in cinemaAllList" :vlaue="item.id" :key="item.id">
                {{item.name}}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-row>
        <a-row>
          <a-form-item class="ui-form__item" name="allocateGroupId" label="分账组"
                       :rules="[{ required: true, message: '请选择分账组' }]">
            <a-select placeholder="请选择" v-model:value="addFormState.allocateGroupId" style="width: 300px;">
              <a-select-option v-for="item in groupList" :vlaue="item.id" :key="item.id">
                {{item.title}}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-row>
        <a-row>
          <a-form-item label="分账时间" name="time">
            <a-time-range-picker v-model:value="addFormState.time" :allowClear="true"/>
          </a-form-item>
        </a-row>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
  import {
    ledgerGroupList, getAllocateGroupCinemaList, allocateGroupCinema, batchAllocateGroupCinema, updateAllocateGroupCinema
  } from '@/service/modules/ledger.js';

  export default {
    model: {
      prop: 'value'
    },
    props: {
      showCinemaModal: {
        type: Boolean,
        default: false
      },
      isEdit: {
        type: Number,
        default: 0,
      },
      cinemaAllList: {
        type: Array,
        default: [],
      },
      groupList: {
        type: Array,
        default: [],
      },
      item: {
        type: Object,
        default: {},
      },
      type: {
        type: Number,
        default: 0,
      }
    },
    data() {
      return {
        loading: false,
        show: false,
        organizationList: [],
        formState: {},
        searchData: {},
        selectedRowKeys: [],
        list: [],
        addFormState: {
          cinemaIds: [],
          allocateGroupId: '',
          time: []
        },
      }
    },
    watch: {
      item: {
        handler(newVal){
          this.addFormState.cinemaIds = newVal.cinemaIds;
          this.addFormState.allocateGroupId = newVal.allocateGroupId;
          this.addFormState.time = newVal.time;
        },
        deep: true,
      },
      showCinemaModal: {
        handler(newVal) {
          this.show = newVal;
        },
        immediate: true,
      },
    },
    created() {
      this.selectedRowKeys = this.value;
      // this.getData();
    },
    methods: {
      onAddGroupClose() {
        this.show = false;
        this.$emit('update:showCinemaModal', false);
        this.$emit('update:isEdit', 0);
      },
      onAddGroupFinish() {
        this.loading = true;
        if (this.isEdit) {
          this.$refs.addForm.validateFields().then(res => {
            const postData = {
              startTime: 0,
              endTime: 0
            };
            postData.id = this.isEdit;
            postData.allocateGroupId = this.addFormState.allocateGroupId;
            if (this.addFormState.time && this.addFormState.time.length) {
              const time0 = this.moment(this.addFormState.time[0]);
              const time1 = this.moment(this.addFormState.time[1]);
              let startTime = this.moment.duration({
                hours: time0.format('HH'),
                minutes: time0.format('mm'),
                seconds: time0.format('ss')
              }).asSeconds();
              let endTime = this.moment.duration({
                hours: time1.format('HH'),
                minutes: time1.format('mm'),
                seconds: time1.format('ss')
              }).asSeconds();
              postData.startTime = startTime;
              postData.endTime = endTime;
            }
            updateAllocateGroupCinema(postData).then(res=>{
              if (res.code == 200) {
                this.show = false;
                this.$emit('update:showCinemaModal', false);
                this.$emit('update:item', {});
                this.$emit('updateData');
              }
              this.loading = false;
              this.$message.success('操作成功');
            }).catch(err=>{
              this.loading = false;
            })
          })
        } else {
          this.$refs.addForm.validateFields().then(res => {
            const postData = {
              startTime: 0,
              endTime: 0
            };
            postData.type = this.type;
            postData.cinemaIds = this.addFormState.cinemaIds.join(',');
            postData.allocateGroupId = this.addFormState.allocateGroupId;
            if (this.addFormState.time && this.addFormState.time.length) {
              const time0 = this.moment(this.addFormState.time[0]);
              const time1 = this.moment(this.addFormState.time[1]);
              let startTime = this.moment.duration({
                hours: time0.format('HH'),
                minutes: time0.format('mm'),
                seconds: time0.format('ss')
              }).asSeconds();
              let endTime = this.moment.duration({
                hours: time1.format('HH'),
                minutes: time1.format('mm'),
                seconds: time1.format('ss')
              }).asSeconds();
              postData.startTime = startTime;
              postData.endTime = endTime;
            }
            batchAllocateGroupCinema(postData).then(res => {
              if (res.code == 200) {
                this.loading = false;
                this.show = false;
                this.$emit('update:showCinemaModal', false);
                this.$emit('update:item', {});
                this.$emit('update:isEdit', 0);
                this.$emit('updateData');
                this.$message.success('操作成功');
              } else {
                this.loading = false;
              }
            }).catch(err => {
              this.loading = false;
            })

          }).catch(err => {
            console.log(err)
            this.loading = false;
          })
        }

      },
    }
  }
</script>

<style scoped>
    .ui-form__item {
        margin-right: 30px;
    }
</style>
